import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { getStorage } from 'firebase/storage';
// import { getFunctions } from 'firebase/functions';
import { getAnalytics } from 'firebase/analytics';

const firebaseApp = initializeApp({
    apiKey: "AIzaSyAACwXis-KLhTp3f8k7adFda-AkURS7r4E",
    authDomain: "in-en-out.firebaseapp.com",
    // databaseURL: "https://ncig-tradein-default-rtdb.firebaseio.com",
    projectId: "in-en-out",
    storageBucket: "in-en-out.appspot.com",
    messagingSenderId: "1085980405320",
    appId: "1:1085980405320:web:bae7a49212f5dcecd0727d",
    measurementId: "G-8NZKDBBV32"
});

getAnalytics(firebaseApp);

export const db = getFirestore(firebaseApp);
export const auth = getAuth(firebaseApp);
export const storage = getStorage(firebaseApp);
// export const fn = getFunctions(firebaseApp);

export default firebaseApp;